import _ from 'lodash'
import type { PlatformEnvData, IPlatformUtils, WixCodeApi, FeatureName, IPlatformLogger, ClientSpecMapAPI, IModelsAPI, IWixCodeNamespacesRegistry, ModuleLoader } from '@wix/thunderbolt-symbols'
import type { AppEssentials } from '@wix/fe-essentials-viewer-platform'
import type { WixCodeViewerAppUtils } from './wixCodeViewerAppUtils'
import type { BootstrapData } from '../types'
import { wixCodeSdkFactories } from '../wixCodeSdks'

export type WixCodeApiFactory = {
	initWixCodeApiForApplication: (appDefinitionId: string, appEssentials: AppEssentials) => Promise<WixCodeApi>
}

type SdkFactory = (appEssentials: AppEssentials, appDefinitionId: string) => { [namespace: string]: any }

export function createWixCodeApiFactory({
	bootstrapData,
	wixCodeViewerAppUtils,
	modelsApi,
	clientSpecMapApi,
	platformUtils,
	createViewerHandlers,
	platformEnvData,
	logger,
	wixCodeNamespacesRegistry,
	moduleLoader,
	onPageWillUnmount,
}: {
	bootstrapData: BootstrapData
	wixCodeViewerAppUtils: WixCodeViewerAppUtils
	modelsApi: IModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
	platformUtils: IPlatformUtils
	createViewerHandlers: (pageId: string) => any
	platformEnvData: PlatformEnvData
	logger: IPlatformLogger
	wixCodeNamespacesRegistry: IWixCodeNamespacesRegistry
	moduleLoader: ModuleLoader
	onPageWillUnmount: (cb: Function) => void
}): WixCodeApiFactory {
	const internalNamespaces = {
		// TODO: move this somewhere else
		events: {
			setStaticEventHandlers: wixCodeViewerAppUtils.setStaticEventHandlers,
		},
	}

	const createWixCodeApiFactories = () =>
		Promise.all(
			_.map(wixCodeSdkFactories, async (loader, name: FeatureName) => {
				const featurePageConfig = modelsApi.getFeaturePageConfig(name)
				const featureSiteConfig = bootstrapData.sdkFactoriesSiteFeatureConfigs[name] || {}
				const sdkFactory = await loader({ modelsApi, clientSpecMapApi, platformEnvData })

				return (appEssentials: AppEssentials, appDefinitionId: string) =>
					sdkFactory({
						featureConfig: { ...featureSiteConfig, ...featurePageConfig },
						handlers: createViewerHandlers(bootstrapData.currentPageId),
						appEssentials,
						platformUtils,
						platformEnvData,
						appDefinitionId,
						moduleLoader,
						onPageWillUnmount,
						wixCodeNamespacesRegistry: {
							get: (namespace: keyof WixCodeApi) => wixCodeNamespacesRegistry.get(namespace, appDefinitionId),
						},
					})
			})
		)

	// @ts-ignore
	const wixCodeSdksPromise: Promise<Array<SdkFactory>> = logger.runAsyncAndReport('createWixCodeApi', createWixCodeApiFactories)

	return {
		initWixCodeApiForApplication: async (appDefinitionId: string, appEssentials: AppEssentials) => {
			const factories = await wixCodeSdksPromise
			const wixCodeSdkArray = await Promise.all(_.map(factories, (factory) => factory(appEssentials, appDefinitionId))) // members API (users) returns a promise.
			const wixCodeApi = Object.assign({}, internalNamespaces, ...wixCodeSdkArray)
			wixCodeNamespacesRegistry.registerWixCodeNamespaces(wixCodeApi, appDefinitionId)
			return wixCodeApi
		},
	}
}
